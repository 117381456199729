module services {
    export module sales {
        export class salesOrderSundryItemService implements interfaces.sales.ISalesOrderSundryItemService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            getDetail(): ng.resource.IResourceClass<interfaces.sales.ISalesOrderSundryItem> {

                return this.$resource<interfaces.sales.ISalesOrderSundryItem>(this.ENV.DSP_URL + "SalesOrderSundryItem/GetDetail", {
                    Id: '@Id',
                    soId: '@soId',
                }, {
                        get: {
                            method: 'GET',
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    });
            }

            getBySalesOrderId(): ng.resource.IResourceClass<interfaces.sales.ISalesOrderSundryItemDisplay> {

                return this.$resource<interfaces.sales.ISalesOrderSundryItemDisplay>(this.ENV.DSP_URL + "SalesOrderSundryItem/GetBySalesOrderId", {
                    soId: '@soId'
                });
            }

            save(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "SalesOrderSundryItem/Save");
            }

            delete(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "SalesOrderSundryItem/Delete", {
                    sosId: '@sosId'
                });
            }

            generateSundryItems(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "SalesOrderSundryItem/GenerateSundryItems", {
                    soId: '@soId'
                });
            }

        }
    }
    angular.module("app").service("salesOrderSundryItemService", services.sales.salesOrderSundryItemService);
}